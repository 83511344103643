import fetcher from 'lux/modules/fetcher';

interface DraftResumeParams {
    vrResponseHash: string;
    shouldSetProfessionalRoleFromVacancy?: boolean;
}

interface DraftResumeResult {
    resumeHash: string;
}

declare global {
    interface FetcherPostApi {
        ['/shards/resume/draft_resume']: {
            queryParams: void;
            body: DraftResumeParams;
            response: DraftResumeResult;
        };
    }
}

interface CreateVrDraftResumeArgs extends DraftResumeParams {
    onFail: (error: unknown) => void;
}

async function createVrDraftResume({
    vrResponseHash,
    shouldSetProfessionalRoleFromVacancy = false,
    onFail,
}: CreateVrDraftResumeArgs): Promise<void> {
    try {
        const {
            data: { resumeHash },
        } = await fetcher.post('/shards/resume/draft_resume', { vrResponseHash, shouldSetProfessionalRoleFromVacancy });

        window.location.assign(`/applicant/resumes/short?resume=${resumeHash}&hhtmFrom=vr_signup_login`);
    } catch (error) {
        onFail(error);
    }
}

export default createVrDraftResume;
